/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";
import Alert from "@local/components/alert";
import style from "./style.module.scss";

const Container = ({ isRestrictedPath, tabs, hasPermission, children, type }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isRestrictedPath || !hasPermission) history.push("/reports");
  }, []);

  const handleChange = (event, index) => {
    history.push({
      pathname: `/reports/${tabs.filter((tab) => tab.isVisible)[index].path}`,
      query: location.query,
    });
  };

  const filteredTabs = tabs.filter((tab) => tab.isVisible);
  const index = filteredTabs.findIndex((tab) => tab.path === type);
  if (index === -1) history.push("/");

  if (hasPermission) {
    return (
      <HeaderNavigation
        index={index}
        title={formatMessage({
          id: "pages.reports.list.cmp.container.idx.reports",
        })}
        tabs={filteredTabs}
        handleChange={handleChange}
      >
        <MainContent>
          <div className={style.alertWrapper}>
            <Alert
              type="warning"
              content={formatMessage({
                id: "pages.reports.list.cmp.container.idx.spamFolderAlert",
              })}
            />
          </div>
          {index !== -1 ? children : undefined}
        </MainContent>
      </HeaderNavigation>
    );
  }

  return <></>;
};

Container.propTypes = {};

export default Container;
